import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, MetricValueDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-metric-value-item-form',
  templateUrl: './metric-value-item-form.component.html',
  styleUrl: './metric-value-item-form.component.scss',
})
export class MetricValueItemFormComponent extends BaseForm<MetricValueDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'METRIC_VALUE');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      notes: new FormControl(null),
      submitter: new FormControl(null),
      attachment: new FormControl(null),
      date: new FormControl(null),
      value: new FormControl(null),
      calculationType: new FormControl(null),
    });
  }
}
